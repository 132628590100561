import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["printing", "design", "total"]

  connect() {
    this.priceChanged()
  }

  priceChanged() {
    var printing = this.parseFee(this.printingTarget.innerText)
    var design = this.parseFee(this.designTarget.value) || 0
    var total = printing + design

    this.totalTarget.innerText = "€" + total.toFixed(2)
    document.dispatchEvent(new Event("priceChanged"))
  }

  parseFee(fee) {
    return parseFloat(fee.replace(",", ".").replace("€", ""))
  }
}
