import ApplicationController from "./application_controller"

var $fileInput = $(".file-input")
var $droparea = $(".file-drop-area")

// highlight drag area
$fileInput.on("dragenter focus click", function () {
  $droparea.addClass("is-active")
})

// back to normal state
$fileInput.on("dragleave blur drop", function () {
  $droparea.removeClass("is-active")
})

// change inner text
$fileInput.on("change", function () {
  var filesCount = $(this)[0].files.length
  var $textContainer = $(this).prev()

  if (filesCount === 1) {
    // if single file is selected, show file name
    var fileName = $(this).val().split("\\").pop()
    $textContainer.text(fileName)
  } else {
    // otherwise show number of files
    $textContainer.text(filesCount + " files selected")
  }
})

export default class extends ApplicationController {
  static targets = [
    "id",
    "fileName",
    "imagesLabel",
    "button",
    "title",
    "material",
    "finish",
    "colors",
    "size",
    "thumbnail",
    "infill"
  ]

  imagesUploaded(e) {
    var sentence =
      e.target.files.length > 1
        ? " images sélectionnées"
        : " image sélectionnée"
    this.imagesLabelTarget.innerHTML =
      e.target.files.length.toString() + sentence
  }

  uploadPart(e) {
    var file = e.srcElement.files[0]
    var formData = new FormData()
    formData.append("file", file)
    this.fileName = file.name
    this.fileNameTarget.innerHTML = file.name
    this.buttonTarget.disabled = true
    this.buttonTarget.innerHTML =
    "<i style='color: white;' class='fa fa-spinner fa-spin'></i>"
    this.titleTarget.value = this.fileName
    this.images = []

    $.ajax({
      type: "POST",
      url: "/post_part",
      data: formData,
      processData: false,
      contentType: false,
      success: (data) => {
        console.log(data)
        const size_x = data["dimensions"]["x"].toFixed(2) || "0"
        const size_y = data["dimensions"]["y"].toFixed(2) || "0"
        const size_z = data["dimensions"]["z"].toFixed(2) || "0"
        this.modelId = data["modelId"]
        this.thumbnail = data["thumbnailUrl"]
        this.images.push(this.thumbnail)
        this.idTarget.value = this.modelId
        this.thumbnailTarget.classList.remove("hidden")
        this.thumbnailTarget.src = this.thumbnail
        this.fileNameTarget.innerHTML = file.name
        this.sizeTarget.value = `${size_x}x${size_y}x${size_z}`
        this.stimulate("FormReflex#get_materials", this.modelId)
      },
    })
  }

  showSpinner(e) {
    this.buttonTarget.innerHTML =
      "<i style='color: white;' class='fa fa-spinner fa-spin'></i>"
  }

  getFinishes(e) {
    var model_id = this.idTarget.value
    var material = this.materialTarget.value
    this.stimulate("FormReflex#get_finishes", model_id, material)
  }

  getColors(e) {
    console.log(this)
    console.log(this.finishTarget)
    var model_id = this.idTarget.value
    var material_id = this.materialTarget.value
    var finish_id = this.finishTarget.value
    this.stimulate(
      "FormReflex#get_colors",
      model_id,
      material_id,
      finish_id
    )
  }

  colorSelected(e) {
    var color = this.infillTarget.value

    if (color) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }

  getMaterialConfigId(e) {
    var color = this.colorsTarget.value
    var model_id = this.idTarget.value
    var material_id = this.materialTarget.value
    var finish_id = this.finishTarget.value

    this.stimulate(
      "FormReflex#get_material_config_id",
      model_id,
      material_id,
      finish_id,
      color
    )
  }

  // REMOVE
  // getTechnologies(e) {
  //   console.log("getTechnologies >>>>>>>")
  //   var model_id = this.idTarget.value
  //   var material = this.materialTarget.value
  //   var finish_id = this.finishTarget.value
  //   console.log(finish_id)
  //   this.stimulate("FormReflex#get_technologies", model_id, material, finish_id)
  // }

  // getQualities(e) {
  //   var shop_id = this.idTarget.value
  //   var material = this.materialTarget.value
  //   var technology = this.technologyTarget.value
  //   this.stimulate("FormReflex#get_qualities", shop_id, material, technology)
  // }



  // getInfills(e) {
  //   var shop_id = this.idTarget.value
  //   var material = this.materialTarget.value
  //   var technology = this.technologyTarget.value
  //   var quality = this.qualityTarget.value
  //   var color = this.colorsTarget.value
  //   this.stimulate(
  //     "FormReflex#get_infills",
  //     shop_id,
  //     material,
  //     technology,
  //     quality,
  //     color
  //   )
  // }

  // getPostProcessings(e) {
  //   var shop_id = this.idTarget.value
  //   var material = this.materialTarget.value
  //   var technology = this.technologyTarget.value
  //   var quality = this.qualityTarget.value
  //   var color = this.colorsTarget.value
  //   var infill = this.infillsTarget.value
  //   this.stimulate(
  //     "FormReflex#get_post_processings",
  //     shop_id,
  //     material,
  //     technology,
  //     quality,
  //     color,
  //     infill
  //   )
  // }

  // postProcessingsChanged(e) {
  //   console.log(this.oneCheckboxIsChecked())
  //   if (this.oneCheckboxIsChecked()) {
  //     this.buttonTarget.disabled = false
  //   } else {
  //     this.buttonTarget.disabled = true
  //   }
  // }

  // oneCheckboxIsChecked() {
  //   var hasOneChecked = false
  //   this.postProcessingsTargets.forEach((cb) => {
  //     if (cb.checked) {
  //       hasOneChecked = true
  //     }
  //   })
  //   return hasOneChecked
  // }
}
