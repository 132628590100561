import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "partPrice",
    "totalPartPrice",
    "totalProductPrice",
    "feeTotal",
    "printTotal",
    "apiPriceId",
    "apiPriceIds",
  ];

  connect() {
    document.addEventListener("recomendedSelected", () =>
      this.recalculateTotal()
    );
  }

  recalculateTotal() {
    var globalTotal = 0;
    var feeTotal = 0;
    this.totalPartPriceTargets.forEach((price) => {
      globalTotal += this.parseFee(price.innerText);
    });

    this.partPriceTargets.forEach((price) => {
      feeTotal += this.parseFee(price.innerText);
    });

    var printTotal = (globalTotal / 100) - feeTotal;

    this.totalProductPriceTarget.innerText = "€" + globalTotal / 100;
    this.feeTotalTarget.innerText = "€" + feeTotal;
    this.printTotalTarget.innerText = "€" + printTotal.toFixed(2);

    var api_prices_ids = [];
    this.apiPriceIdTargets.forEach((id) => {
      api_prices_ids.push(id.value);
    });

    this.apiPriceIdsTarget.value = api_prices_ids;
  }

  parseFee(fee) {
    return parseFloat(fee.replace(".", "").replace(",", ".").replace("€", ""));
  }
}
