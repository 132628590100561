import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "printing",
    "shippingR",
    "designR",
    "shippingF",
    "designF",
    "shippingC",
    "designC",
    "total",
    "shipping",
    "recommendedId",
    "cheapestId",
    "fastestId",
    "recommended",
    "cheapest",
    "fastest",
    "apiPriceId",
    "partPrice",
    "totalPartPrice",
  ];

  connect() {
    this.recommendedSelected();
  }

  recommendedSelected() {
    this.recommendedTarget.classList.add("price-selected");
    this.cheapestTarget.classList.remove("price-selected");
    this.fastestTarget.classList.remove("price-selected");

    var designR = this.parseFee(this.designRTarget.innerText);
    var shippingR = this.parseFee(this.shippingRTarget.innerText);

    this.apiPriceIdTarget.value = this.recommendedIdTarget.innerText.trim();
    this.updatePartPrice(designR, shippingR);
    document.dispatchEvent(new Event("recomendedSelected"));
  }

  cheapestSelected() {
    this.recommendedTarget.classList.remove("price-selected");
    this.cheapestTarget.classList.add("price-selected");
    this.fastestTarget.classList.remove("price-selected");

    var designC = this.parseFee(this.designCTarget.innerText);
    var shippingC = this.parseFee(this.shippingCTarget.innerText);

    this.apiPriceIdTarget.value = this.cheapestIdTarget.innerText.trim();
    this.updatePartPrice(designC, shippingC);
  }

  fastestSelected() {
    this.recommendedTarget.classList.remove("price-selected");
    this.cheapestTarget.classList.remove("price-selected");
    this.fastestTarget.classList.add("price-selected");

    var designF = this.parseFee(this.designFTarget.innerText);
    var shippingF = this.parseFee(this.shippingFTarget.innerText);

    this.apiPriceIdTarget.value = this.fastestIdTarget.innerText.trim();
    this.updatePartPrice(designF, shippingF);
  }

  updatePartPrice(printPrice, shippingPrice) {
    var partPrice = this.parseFee(this.partPriceTarget.innerText);
    var total = printPrice + shippingPrice + partPrice;

    this.totalPartPriceTarget.innerText = "€" + total.toFixed(2);
  }

  parseFee(fee) {
    return parseFloat(fee.replace(".", "").replace(",", ".").replace("€", ""));
  }
}
