import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["price", "total"]

  connect() {
    document.addEventListener("priceChanged", () => this.recalculateTotal())
  }

  recalculateTotal() {
    var globalTotal = 0
    this.priceTargets.forEach((price) => {
      globalTotal += this.parseFee(price.innerText)
    })
    this.totalTarget.innerText = "€" + globalTotal.toFixed(2)
  }

  parseFee(fee) {
    return parseFloat(fee.replace("€", ""))
  }
}
