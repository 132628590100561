import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "size", "fileName"]

  generateImages(e) {
    this.images = []
    this.stl_viewer = new StlViewer(document.getElementById("hidden_canvas"), {
      cameray: -50,
      models: [{ id: 1, local_file: this.fileNameTarget.files[0] }],
      model_loaded_callback: () => {
        this.getPictures()
        // this.getDimensions()
      },
    })
  }

  getImage(x, y, z) {
    this.stl_viewer.rotate(1, x, y, z)
    var dataURL = document
      .getElementById("hidden_canvas")
      .querySelector("canvas")
      .toDataURL("image/png")
    this.images.push(dataURL)
  }

  getPictures() {
    let angles = [
      [0, 0, 2], // Horizontal
      [0, 0, 2], // Horizontal
      [0, 0, 2], // Horizontal
      [0, 0, 1], // Horizontal
      [-0.5, 0, 0], // Vertical
      [-0.5, 0, 0], // Vertical
      [-0.5, 0, 0], // Vertical
      [-0.5, 0, 0], // Vertical
      [-1, 0, 0], // Vertical
    ]
    var delay = 250
    angles.forEach((angle) => {
      delay += 250
      setTimeout(this.getImage.bind(this), delay, angle[0], angle[1], angle[2])
    })
    setTimeout(() => {
      var canvas = document
        .getElementById("hidden_canvas")
        .querySelector("canvas")
      canvas.parentNode.removeChild(canvas)
      this.fieldTarget.value = this.images.join()
    }, delay + 1000)
  }

  getDimensions() {
    var infos = this.stl_viewer.get_model_info(1)
    var dims =
      infos.dims.x.toFixed(2) +
      "x" +
      infos.dims.y.toFixed(2) +
      "x" +
      infos.dims.z.toFixed(2) +
      infos.units
    this.sizeTarget.value = dims
  }
}
