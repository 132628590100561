import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["pageOne", "pageTwo"]

  goToPageOne() {
    this.pageOneTarget.classList.remove("hidden")
    this.pageTwoTarget.classList.add("hidden")
  }

  goToPageTwo() {
    this.pageOneTarget.classList.add("hidden")
    this.pageTwoTarget.classList.remove("hidden")
  }
}
