import $ from 'jquery';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["phoneNumber", "code", "button", "verifyButton"]
  connect(){
    this.disableButton()
  }

  // checkButton(e){
  //   if(e.currentTarget.value.length > 8 ){
  //     this.verifyButtonTarget.removeAttribute('disabled')
  //   } else {
  //     this.verifyButtonTarget.setAttribute('disabled', 'true')
  //   }
  // }

  // sendMessage(){
  //   if(this.phoneNumberTarget.value.length > 8 ) {
  //     $.ajax({
  //       url: "/send_verification_message",
  //       method: "POST",
  //       data: {
  //         number:  this.phoneNumberTarget.value
  //       }
  //     });
  //   }
  // }

  // verifyCode(){
  //   $.ajax({
  //     url: "/verify_code",
  //     method: "POST",
  //     data: {
  //       number:  this.phoneNumberTarget.value,
  //       code:  this.codeTarget.value
  //     }
  //   });
  // }
  disableButton() {
    this.buttonTarget.disabled = false;
  }
}
